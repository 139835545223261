import { useMemo } from 'react';
import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { Label, Select } from '@kiper/ui';
import { sharedPlaceSelect } from '@kiper/monitoring-graphql/place_select/query';

export default function PlaceCondominiumCommercial({
  commercialCondominiumId,
  disabledValues,
  invalid,
  value,
  isEditable,
  ...props
}) {
  const { data, loading } = useQuery(sharedPlaceSelect, {
    fetchPolicy: 'no-cache',
    skip: !commercialCondominiumId,
    variables: {
      commercialCondominiumId,
    },
  });

  const places = useMemo(() => {
    if (!data) return [];

    const parsedOptions = data.sharedPlaceSelect?.map(i => ({
      value: i.id,
      label: i.name,
      isDisabled: disabledValues?.some(j => j.value === Number(i.id)),
    }));

    return parsedOptions;
  }, [data, disabledValues]);

  if (!isEditable) return <Label>{value && value.label}</Label>;

  const getValue = () => {
    const hasSharedPlace = value && data?.sharedPlaceSelect?.length;
    const isNumberOrString =
      typeof value === 'number' || typeof value === 'string';

    if (hasSharedPlace && isNumberOrString) {
      const findOptionObject = data.sharedPlaceSelect.find(
        i => Number(i.id) === Number(value),
      );
      return findOptionObject;
    }

    return value;
  };

  return (
    <Select
      {...props}
      isDisabled={!commercialCondominiumId}
      isLoading={loading}
      options={places}
      invalid={Number(invalid)}
      value={getValue()}
      isClearable
    />
  );
}

PlaceCondominiumCommercial.propTypes = {
  commercialCondominiumId: propTypes.number.isRequired,
  disabledValues: propTypes.array,
  invalid: propTypes.bool,
  value: propTypes.any,
  isEditable: propTypes.bool,
};

PlaceCondominiumCommercial.defaultProps = {
  disabledValues: [],
  invalid: false,
  value: '',
  isEditable: true,
};
