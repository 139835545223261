import { useState, useMemo, useContext } from 'react';
import propTypes from 'prop-types';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import {
  exitGuidedAttendance,
  finishEvent,
  insertGuidedAttendanceData,
} from '@kiper/monitoring-graphql/guided_attendance';

import { Modal, Flex, Text, Input, Button, ErrorMessage } from '@kiper/ui';
import { Form } from 'reactstrap';
import {
  EventAttendanceContext,
  GuidedAttendanceContext,
  TriageContext,
} from '../../../store';
import { sendAmplitudeData } from '../../../services/amplitude';

import * as S from './styles';
import { closureReasonFieldName } from '../../../constants';
import { useGuidedAttendance } from '../../../hooks';

const options = Object.freeze({
  END: 'end',
  EXIT: 'exit',
});

function ExitGuidedAttendanceModal({ onToggle, open, event }) {
  const [t] = useTranslation('guided-attendance');
  const [option, setOption] = useState(null);
  const history = useHistory();
  const eventId = history?.location?.pathname?.split('/')?.[2];

  const { handleClear: handleClearTriage } = useContext(TriageContext);
  const {
    contextTree: { data, tree, currentStep, hideAttendanceDetailInfo },
  } = useContext(GuidedAttendanceContext);
  const { handleCheckToClearEventFilter } = useContext(EventAttendanceContext);
  const { handleValidHasSubtriage } = useGuidedAttendance();

  const schema = yup.object({
    reason: yup
      .string()
      .trim()
      .min(5, t('common:feedback.min', { number: 5 }))
      .required(t('common:feedback.required-field')),
  });

  const hasSubtriage = useMemo(() => {
    return handleValidHasSubtriage(event?.condominium);
  }, [event]);

  const showExitButton = useMemo(
    () =>
      !hideAttendanceDetailInfo &&
      (!hasSubtriage ||
        (hasSubtriage &&
          !!data?.stepAttendanceGetReason?.id &&
          !!data?.stepGetSubtriage?.id)),
    [data, hasSubtriage, hideAttendanceDetailInfo],
  );

  const [insertRedisData] = useMutation(insertGuidedAttendanceData, {
    onCompleted: () => {
      onToggle();
    },
  });

  const [exit, { loading: loadingExit }] = useMutation(exitGuidedAttendance, {
    onCompleted: () => {
      insertRedisData({
        variables: {
          data: {
            currentStep: currentStep?.id || 99,
            data: JSON.stringify({ ...data, isInFallback: true }),
            rootAttendanceStepId: tree.attendanceStepId,
            eventId,
          },
        },
      });
      history.push(`/guided-attendance/${eventId}/fallback`);
    },
  });

  const [finish, { loading: loadingFinish }] = useMutation(finishEvent, {
    onCompleted: () => {
      localStorage.removeItem(eventId);
      handleClearTriage();
      handleCheckToClearEventFilter();
    },
  });

  const loading = useMemo(() => loadingExit || loadingFinish, [
    loadingExit,
    loadingFinish,
  ]);

  const onSubmit = values => {
    if (option === options.EXIT) {
      const { reason } = values;

      const variables = {
        eventId,
        reason,
      };

      sendAmplitudeData('exit guided attendance', {
        ...variables,
      });

      exit({
        variables,
      });
    }
    if (option === options.END) {
      finish({
        variables: {
          treatment: null,
          closureReason: closureReasonFieldName.LACK_OF_COMMUNICATION,
        },
      });
    }
  };

  const {
    values,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
    setFieldTouched,
  } = useFormik({
    validationSchema: schema,
    initialValues: { reason: '' },
    onSubmit,
  });

  const handleChangeOption = opt => {
    setOption(opt);
    if (opt === options.END) {
      setFieldValue('reason', t('header.exit-modal.options.end'));
    } else {
      setFieldValue('reason', '');
      setFieldTouched('reason', false);
    }
  };

  return (
    <Modal
      open={open}
      toggle={onToggle}
      size="md"
      title={t('header.exit-modal.title')}
    >
      <Form onSubmit={handleSubmit}>
        {(!option || option === options.END) && (
          <Flex gridGap="8px" flexDirection="column">
            <Text>{t('header.exit-modal.text')}</Text>
            <S.RadioButton
              id="exit-or-end-guided-attendance"
              value={option || ''}
              variant="out"
              color={option === options.END ? 'primary' : 'neutral'}
              name="option"
              onChange={() => handleChangeOption(options.END)}
              label={t('header.exit-modal.options.end')}
              showRadioInput
              checked={option === options.END}
            />
            {showExitButton && (
              <S.RadioButton
                id="exit-or-end-guided-attendance"
                value={option || ''}
                variant="out"
                color={option === options.EXIT ? 'primary' : 'neutral'}
                name="option"
                onChange={() => handleChangeOption(options.EXIT)}
                label={t('header.exit-modal.options.exit')}
                showRadioInput
                checked={option === options.EXIT}
              />
            )}
          </Flex>
        )}

        {option === options.EXIT && (
          <Flex flexDirection="column" width="100%" gridGap="8px">
            <Trans>
              <Text>
                {t('header.exit-modal.text', {
                  whatDidYouMiss: t('header.exit-modal.what-did-you-miss'),
                })}
              </Text>
            </Trans>
            <Input
              type="textarea"
              placeholder={t('header.exit-modal.placeholder')}
              id="reason"
              name="reason"
              value={values?.reason}
              onChange={setFieldValue}
              rows={2}
              invalid={!!touched.reason && !!errors.reason}
            />
            {!!touched.reason && !!errors.reason && (
              <ErrorMessage>{errors.reason}</ErrorMessage>
            )}
          </Flex>
        )}
        <Flex mt="32px" justifyContent="flex-end" gridGap="8px">
          <Button type="button" color="secondary" outline onClick={onToggle}>
            {t('buttons:cancel')}
          </Button>
          <Button
            type="submit"
            color="danger"
            out
            disabled={loading || !option}
          >
            {option === options.END
              ? t('header.end-guided-attendance')
              : t('header.exit-guided-attendance')}
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
}

export default ExitGuidedAttendanceModal;

ExitGuidedAttendanceModal.propTypes = {
  onToggle: propTypes.func.isRequired,
  open: propTypes.bool.isRequired,
  event: propTypes.object.isRequired,
};
