import { useEffect, useRef, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { query } from '@kiper/monitoring-graphql/camera';

const useGetCameraLive = ({ selectedCamera, condominiumPersonContextId }) => {
  const [loadingImg, setLoadingImg] = useState(true);
  const [cameraOffline, setCameraOffline] = useState(false);

  const camRef = useRef(null);

  const [cameraLive, { loading: loadingCameraLive }] = useLazyQuery(
    query.cameraLive,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ cameraLive: live }) => {
        if (camRef?.current) {
          if (camRef.current?.id === selectedCamera?.id) {
            camRef.current.src = live?.url;
          } else {
            camRef.current.src = '';
          }
        }
      },
      onError: () => {
        setCameraOffline(true);
        setLoadingImg(false);
      },
    },
  );

  const handleImageLoaded = () => {
    setLoadingImg(false);
  };

  const handleCameraError = () => {
    setCameraOffline(true);
    handleImageLoaded();
  };

  useEffect(() => {
    if (selectedCamera?.id) {
      if (camRef?.current) camRef.current.src = '';
      setCameraOffline(false);
      setLoadingImg(true);
      cameraLive({
        variables: {
          cameraId: selectedCamera?.id,
          startDateTime: null,
          condominiumPersonContextId,
        },
      });
    } else {
      if (camRef?.current) camRef.current.src = '';
      setLoadingImg(false);
    }
  }, [selectedCamera]);

  return {
    loadingCameraLive,
    loadingImg,
    cameraOffline,
    handleImageLoaded,
    handleCameraError,
    camRef,
  };
};

export default useGetCameraLive;
