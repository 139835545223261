import { useMemo } from 'react';
import { erpLicenses } from '../../../../constants';

const useCommercialLicense = condominium => {
  const hasCommercialLicense = useMemo(() => {
    const licenseParam = condominium?.params?.find(x => x.name === 'erpLicense')
      ?.value;
    const erpLicenseList = licenseParam ? JSON.parse(licenseParam) : {};

    return erpLicenseList?.licenses?.find(
      x => x.licenseType === erpLicenses.commercial,
    );
  }, [condominium, erpLicenses]);

  const commercialPersonContextId = useMemo(() => {
    if (hasCommercialLicense) {
      return +condominium?.personContextId;
    }
    return null;
  }, [hasCommercialLicense, condominium]);

  return { hasCommercialLicense, commercialPersonContextId };
};

export default useCommercialLicense;
