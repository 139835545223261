import { useSwal } from '@kiper/hooks';
import { mutations } from '@kiper/monitoring-graphql/unity';
import { useMutation } from 'react-apollo';
import { useFormatApolloErrorHandler } from '../../../../hooks';

const useUpdateUnity = (t, handleToggle) => {
  const { toast } = useSwal();
  const { onError } = useFormatApolloErrorHandler();

  const [update] = useMutation(mutations.unityUpdate, {
    refetchQueries: ['unitiesByUnityGroup'],
    onCompleted: () => {
      toast.fire({
        title: t('details.modal.unity.updated'),
        icon: 'success',
      });
      handleToggle();
    },
    onError,
  });

  return { update };
};

export default useUpdateUnity;
