import { useState } from 'react';

const useAccessProfileSelection = (unity, values, setFieldValue) => {
  const [
    ableSelectAccessProfileToUnity,
    setAbleSelectAccessProfileToUnity,
  ] = useState(!unity?.serializedParams?.defaultAccessProfile);

  const handleCheckAbleSelectAccessProfileToUnity = () => {
    if (values?.defaultAccessProfile) {
      setFieldValue('defaultAccessProfile', null);
    }

    if (values?.serializedParams?.defaultAccessProfile) {
      setFieldValue('serializedParams.defaultAccessProfile', null);
    }

    setAbleSelectAccessProfileToUnity(prevState => !prevState);
  };

  return {
    ableSelectAccessProfileToUnity,
    handleCheckAbleSelectAccessProfileToUnity,
  };
};

export default useAccessProfileSelection;
