import { getPlacesToOpenDoor } from '@kiper/monitoring-graphql/guided_attendance';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';

const useGetPlaces = ({ event }) => {
  const { values } = useFormikContext();
  const [places, setPlaces] = useState([]);
  const [selectedPlace, setSelectedPlace] = useState(event?.placeId || null);

  const handleSelectedPlace = place => {
    if (selectedPlace?.id !== place?.id) {
      setSelectedPlace(place);
    }
  };

  const [getPlaces, { loading }] = useLazyQuery(getPlacesToOpenDoor, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ placesToOpenDoor }) => {
      if (placesToOpenDoor?.places?.length) {
        setPlaces(placesToOpenDoor.places);
        if (!selectedPlace) {
          setSelectedPlace(placesToOpenDoor.places[0]);
        }
      }
    },
  });

  useEffect(() => {
    const unityId = values?.stepAttendanceGetUnity?.unity?.id;
    const condominiumPersonContextId = event?.condominium?.personContextId;

    if (condominiumPersonContextId || unityId) {
      getPlaces({
        variables: {
          condominiumPersonContextId,
          unityId,
          placeId: null,
        },
      });
    }
  }, [values?.stepAttendanceGetUnity, event]);

  return { places, selectedPlace, handleSelectedPlace, loading };
};

export default useGetPlaces;
