import { useRef } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { expectedGuests } from '@kiper/monitoring-graphql/invite';
import UsersGuestRow from './UsersGuestsRow';
import { InfiniteScrollTable } from '../../../InfiniteScroll';

function UsersGuests({ event, onToggle, formik, filters, setGuestsCount }) {
  const { t } = useTranslation('user');

  const columns = useRef([
    {
      name: t('list.table-header.unity-group'),
      align: 'left',
      width: '15%',
    },
    {
      name: t('list.table-header.host'),
      align: 'left',
      width: '20%',
    },
    {
      name: t('list.table-header.guest'),
      align: 'left',
      width: '20%',
    },
    {
      name: t('list.table-header.personal-data'),
      align: 'left',
      width: '15%',
    },
    {
      name: t('list.table-header.start'),
      align: 'left',
      width: '10%',
    },
    {
      name: t('list.table-header.end'),
      align: 'left',
      width: '10%',
    },
  ]);

  return (
    <InfiniteScrollTable
      columns={columns}
      query={expectedGuests}
      variables={{
        condominiumId: event?.condominium?.personContext?.treeNodeId,
        filters: {
          ...filters,
          sourceNodeId:
            Number(filters?.sourceNodeId) ||
            event?.condominium?.personContext?.treeNodeId,
        },
      }}
      onCompletedCallback={res => {
        setGuestsCount(res?.expectedGuests?.pagination?.totalResults > 0);
      }}
      renderListItem={listProps => (
        <UsersGuestRow
          formik={formik}
          onToggle={onToggle}
          event={event}
          filters={filters}
          {...listProps}
        />
      )}
    />
  );
}

export default UsersGuests;

UsersGuests.propTypes = {
  event: propTypes.object.isRequired,
  formik: propTypes.object.isRequired,
  filters: propTypes.any.isRequired,
  handlePageChange: propTypes.func.isRequired,
  setGuestsCount: propTypes.func.isRequired,
  setFilters: propTypes.func.isRequired,
  onToggle: propTypes.func,
};
UsersGuests.defaultProps = {
  onToggle: null,
};
