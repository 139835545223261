import propTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { Label, Select } from '@kiper/ui';
import { placeTypeLabel as placeTypeLabelGql } from '@kiper/monitoring-graphql';

export default function PlaceTypeLabelSelect({
  isClearable,
  invalid,
  value,
  isEditable,
  personContextId,
  fieldName,
  ...props
}) {
  const { data, loading } = useQuery(
    placeTypeLabelGql.queries.placeTypeLabelsSelect,
    {
      skip: !personContextId,
      variables: {
        personContextId,
        fieldName,
      },
    },
  );
  if (!isEditable) return <Label>{value && value.label}</Label>;

  const getValue = placeTypeLabel => {
    if (!placeTypeLabel) return '';

    return data?.placeTypeLabelsSelect?.find(
      p => +p?.value === +placeTypeLabel?.value,
    );
  };

  return (
    <Select
      {...props}
      value={getValue(value)}
      isClearable={isClearable}
      isLoading={loading}
      isDisabled={!personContextId}
      options={(data && data.placeTypeLabelsSelect) || []}
      invalid={Number(invalid)}
    />
  );
}

PlaceTypeLabelSelect.propTypes = {
  value: propTypes.any,
  isClearable: propTypes.bool,
  invalid: propTypes.bool,
  isEditable: propTypes.bool,
  personContextId: propTypes.number,
  fieldName: propTypes.string.isRequired,
};

PlaceTypeLabelSelect.defaultProps = {
  isClearable: false,
  invalid: false,
  isEditable: true,
  value: null,
  personContextId: undefined,
};
