import http from './api';
import authHeaders from './headers';

function dataURItoBlob(dataURI) {
  const binary = atob(dataURI.split(',')[1]);
  const array = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: 'image/jpeg' });
}

export const uploadFace = async ({ data, event, sync }) => {
  const authorizationHeaders = authHeaders();
  const { screenshot, transientPersonId } = data;
  const formData = new FormData();

  const hashRandom = Math.random()
    .toString(36)
    .substring(4);

  formData.append('face', dataURItoBlob(screenshot));
  formData.append('hash', hashRandom);
  formData.append('eventid', event?.eventId);
  formData.append(
    'condominiumpersoncontextid',
    event?.condominium?.personContextId,
  );
  formData.append('sync', sync);

  const headers = {
    'Content-Type': 'multipart/form-data',
    ...authorizationHeaders,
  };

  const res = await http.post(
    `/transientPerson/${transientPersonId}/uploadFacePhoto`,
    formData,
    {
      headers,
    },
  );

  return res.status === 200;
};

export default { uploadFace };
