import styled from 'styled-components';

export const BadgeAlert = styled.div`
  width: 8px;
  height: 8px;
  right: -16px;
  top: -16px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.danger500};
`;
