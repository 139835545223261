import { useContext, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { Button, Flex, MdIcons } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@kiper/hooks';
import { TechnicalReviewEventModal } from '../../../../Modals';
import ObservationModal from '../ObservationModal';
import { EventAttendanceContext } from '../../../../../store';
import { useFinishEvent, useLocalStorage } from '../../hooks';
import { useInsertStepHistory } from '../../../../../hooks';
import { BadgeAlert } from './styles';

const EventActions = ({ event }) => {
  const { t } = useTranslation('guided-attendance');
  const { eventOnTechnicalReviewInAttendance } = useContext(
    EventAttendanceContext,
  );
  const [
    openTechnicalReviewEventModal,
    toggleTechnicalReviewEventModal,
  ] = useToggle(false);
  const [isEventTechnical, setIsEventTechnical] = useState(false);
  const [showObsModal, setShowObsModal] = useState(false);

  const { handleInsertStepHistory } = useInsertStepHistory();
  const { localStorageData, handleClearLocalStorage } = useLocalStorage(
    'event-treatment',
    event?.eventId,
  );
  const { finish, loadingFinish } = useFinishEvent({
    handleClearLocalStorage,
  });

  const handlerToggleObservationModal = () => {
    setShowObsModal(!showObsModal);
  };

  const handleFinishEvent = async () => {
    const data = { finish: true };
    Promise.all([
      finish({
        variables: {
          treatment: localStorageData,
        },
      }),
      handleInsertStepHistory(data, event?.eventId),
    ]);
  };

  useEffect(() => {
    setIsEventTechnical(!!eventOnTechnicalReviewInAttendance);
  }, []);

  return (
    <>
      <Flex justifyContent="space-between" pb="8px">
        <Flex gridGap="8px">
          <Button
            size="sm"
            variant="outlined"
            color="neutral"
            icon={<MdIcons mdIconName="edit_document" size="20px" />}
            onClick={handlerToggleObservationModal}
          >
            <Flex>
              {t('step-open-doors.action.observation')}
              {localStorageData && <BadgeAlert />}
            </Flex>
          </Button>
          <Button
            icon={
              isEventTechnical ? (
                <MdIcons mdIconName="engineering" size="20px" />
              ) : (
                <MdIcons mdIconName="construction" size="20px" />
              )
            }
            size="sm"
            type="button"
            color="danger"
            variant="out"
            onClick={toggleTechnicalReviewEventModal}
          >
            {isEventTechnical
              ? t('step-open-doors.action.open-service-order')
              : t('step-open-doors.action.put-on-technical-review')}
          </Button>
        </Flex>
        <Button
          type="submit"
          size="sm"
          color="primary"
          icon={<MdIcons mdIconName="check" size="20px" />}
          disabled={loadingFinish}
          onClick={handleFinishEvent}
        >
          {t('step-open-doors.action.finish-event')}
        </Button>
      </Flex>
      {openTechnicalReviewEventModal && (
        <TechnicalReviewEventModal
          onToggle={toggleTechnicalReviewEventModal}
          event={event}
          createServiceOrder={isEventTechnical}
        />
      )}
      {showObsModal && (
        <ObservationModal
          eventId={event.eventId}
          open={showObsModal}
          toggleModal={handlerToggleObservationModal}
        />
      )}
    </>
  );
};

export default EventActions;

EventActions.propTypes = {
  event: propTypes.object.isRequired,
};
