import { useEffect, useState } from 'react';
import { useLazyQuery } from 'react-apollo';
import { queries } from '@kiper/monitoring-graphql/condominium/list';
import useCurrentLoggedContext from '../../../../hooks/useCurrentLoggedContext';
import { erpLicenses } from '../../../../constants';

const condominiumParamNames = Object.freeze({
  COMMERCIAL_PERSON_CONTEXT_ID: 'commercialPersonContextId',
});

const useCondominiumCommercialUnity = ({
  hasCommercialLicense,
  condominiumCommercialId,
}) => {
  const [commercialUnitsOptions, setCommercialUnitsOptions] = useState([]);
  const { loggedContext } = useCurrentLoggedContext();

  const handleGetCondominiumCommercialUnitFiltered = condominiums => {
    const commercialUnits = condominiums?.collection
      ?.filter(({ params }) => {
        const commercialParamValue = params?.find(
          param =>
            param?.name === condominiumParamNames.COMMERCIAL_PERSON_CONTEXT_ID,
        )?.value;

        const isCommercialCondominiumUnlinked =
          commercialParamValue === condominiumCommercialId ||
          !commercialParamValue;

        return isCommercialCondominiumUnlinked;
      })
      ?.map(({ personContextId, name }) => ({
        value: +personContextId,
        label: name,
      }));
    return commercialUnits;
  };

  const [getCommercialUnits, { loading }] = useLazyQuery(queries.condominiums, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ condominiums }) => {
      const commercialUnits = handleGetCondominiumCommercialUnitFiltered(
        condominiums,
      );
      if (commercialUnits?.length) setCommercialUnitsOptions(commercialUnits);
    },
  });

  const handleGetCondominiumCommercialUnit = () => {
    getCommercialUnits({
      variables: {
        filters: {
          searchText: '',
          licenses: [erpLicenses.commercial_unity],
          page: 1,
          pagesize: 100,
          orderby: 'name',
          ordination: false,
          sourceNodeId: loggedContext.topNodeId,
        },
      },
    });
  };

  const getCommercialUnityValue = condominiumCommercialUnityValue => {
    return (
      commercialUnitsOptions.find(
        option => option.value === condominiumCommercialUnityValue,
      ) || ''
    );
  };

  useEffect(() => {
    if (hasCommercialLicense) {
      handleGetCondominiumCommercialUnit();
    }
  }, [hasCommercialLicense]);

  return {
    commercialUnitsOptions,
    getCommercialUnityValue,
    loadingCommercialUnits: loading,
  };
};

export default useCondominiumCommercialUnity;
