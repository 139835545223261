import { Suspense, useContext } from 'react';
import { Loader, withErrorBoundary } from '@kiper/ui';
import * as i18n from 'react-i18next';
import {
  useCurrentLoggedContext,
  useCondominiumRemoteConfig,
} from '../../../../hooks';
import context from '../CondominiumDetailContext';
import Provider from '../CondominiumDetailProvider';
import PageHeader from '../../../../components/PageHeader';
import TabHeaderPage from '../../../../components/TabHeaderPage';
import { useTabChange } from './hooks';
import TabContent, {
  HeaderButton,
  HeaderTitle,
  ActivityManagement,
  AttendanceSettings,
  GeneralData,
  UnityConfiguration,
  ApplicationParameters,
  SipConfiguration,
  RulesManagement,
  Exportations,
} from './components';
import * as S from '../styles';

const CondominiumDetail = () => {
  const [t] = i18n.useTranslation('condominium');
  const { loggedContext } = useCurrentLoggedContext();

  const { condominium, breadcrumb, updateCondominium } = useContext(context);

  const { activeTab, handleChangeTab, tabs } = useTabChange(
    loggedContext,
    condominium,
    t,
    S,
  );

  const {
    showAttendanceSettings,
    showApplicationParameters,
    showResyncCond,
    showVideoCallFeature,
  } = useCondominiumRemoteConfig(loggedContext, condominium);

  return (
    <>
      <PageHeader
        breadcrumb={breadcrumb}
        title={<HeaderTitle condominium={condominium} t={t} />}
        t={t}
        tabHeaderPage={
          <TabHeaderPage
            tabs={tabs}
            activeTab={activeTab}
            onChangeTab={handleChangeTab}
          />
        }
        headerButton={
          <HeaderButton
            condominium={condominium}
            showResyncCond={showResyncCond}
            t={t}
          />
        }
      />

      <TabContent activeTab={activeTab}>
        <GeneralData />
        <AttendanceSettings
          condominium={condominium}
          activeTab={activeTab}
          showAttendanceSettings={showAttendanceSettings}
        />
        <ApplicationParameters
          condominium={condominium}
          activeTab={activeTab}
          showApplicationParameters={showApplicationParameters}
        />
        <UnityConfiguration
          condominium={condominium}
          activeTab={activeTab}
          handleChangeTab={handleChangeTab}
        />
        <ActivityManagement condominium={condominium} activeTab={activeTab} />
        <SipConfiguration
          condominium={condominium}
          activeTab={activeTab}
          showSipSettings={showVideoCallFeature}
          updateCondominium={updateCondominium}
        />
        <RulesManagement condominium={condominium} activeTab={activeTab} />
        <Exportations condominium={condominium} activeTab={activeTab} />
      </TabContent>
    </>
  );
};

const CondominiumDetailWithProvider = ({ ...props }) => {
  return (
    <Suspense fallback={<Loader fullHeight />}>
      <Provider {...props}>
        <CondominiumDetail {...props} />
      </Provider>
    </Suspense>
  );
};

const CondominiumDetailComponent = withErrorBoundary(
  CondominiumDetailWithProvider,
  {
    i18n,
    translationKey: 'condominium',
    defaultTitleMessage: 'details.error-boundary.fallback-title',
    customErrorMessage: '',
  },
);

export default CondominiumDetailComponent;
