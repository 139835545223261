import {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import propTypes from 'prop-types';
import * as yup from 'yup';
import { useFormikContext } from 'formik';
import { useSwal, useWindowDimensions } from '@kiper/hooks';
import { apolloErrorHandler } from '@kiper/fns';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorMessage, Flex, MdIcons, Text } from '@kiper/ui';
import { useLazyQuery } from 'react-apollo';
import html2canvas from 'html2canvas';
import { MdVideocamOff } from 'react-icons/md';
import { getCondominiumCameraLiveV2 } from '@kiper/monitoring-graphql/guided_attendance';
import { uploadFace } from '../../../services/api/uploadFace';
import {
  useAttendanceRemoteConfig,
  useCurrentLoggedContext,
  useGuidedAttendance,
  useInsertStepHistory,
} from '../../../hooks';
import { GuidedAttendanceContext } from '../../../store';
import MJPEGPlayer from './MJPEGPlayer';
import PlayerButtonContent from './PlayerButtonContent';
import { SelectRegistrationCameraLocation } from './components';
import { useLocalStorage } from './hooks';
import StepStructure, { StepTextTypeColor } from '../StepStructure';

import * as S from './styles';

const MEDIA_ASPECT_RATIO_CONFIG = {
  HD: {
    CAPTURED_PHOTO: {
      width: 172,
      height: 262,
    },
    VIDEO_STREAM: {
      width: 466,
      height: 262,
    },
  },
  FULL_HD: {
    CAPTURED_PHOTO: {
      width: 240,
      height: 360,
    },
    VIDEO_STREAM: {
      width: 640,
      height: 360,
    },
  },
};

const StepPhotoRegister = ({ event, history, eventId }) => {
  const { toast } = useSwal();
  const { width: windowsWidth } = useWindowDimensions();
  const { t } = useTranslation('guided-attendance');
  const { handleNextAction, handleGetTriageSelected } = useGuidedAttendance();
  const { setContextTree } = useContext(GuidedAttendanceContext);
  const { handleInsertStepHistory } = useInsertStepHistory();
  const videoRef = useRef(null);
  const imgRef = useRef(new Image());
  const { loggedContext } = useCurrentLoggedContext();
  const { enableSyncFaceOnUploadImage } = useAttendanceRemoteConfig(
    loggedContext,
    event?.condominium,
  );
  const [videoAspectRatio, setVideoAspectRatio] = useState({
    width: MEDIA_ASPECT_RATIO_CONFIG.FULL_HD.VIDEO_STREAM.width,
    height: MEDIA_ASPECT_RATIO_CONFIG.FULL_HD.VIDEO_STREAM.height,
  });
  const [capturedPhotoAspectRatio, setCapturedPhotoAspectRatio] = useState({
    width: MEDIA_ASPECT_RATIO_CONFIG.FULL_HD.CAPTURED_PHOTO.width,
    height: MEDIA_ASPECT_RATIO_CONFIG.FULL_HD.CAPTURED_PHOTO.height,
  });

  const [cameraPlaceSelected, setCameraPlaceSelected] = useState(null);
  const [capturingPhoto, setCapturingPhoto] = useState(false);
  const [loadingLive, setLoadingLive] = useState(true);
  const [loadingSubmitPhoto, setLoadingSubmitPhoto] = useState(false);
  const [photoSent, setPhotoSent] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [errorLoadingImg, setErrorLoadingImg] = useState(false);
  const {
    values,
    setFieldValue,
    setFieldError,
    errors,
    touched,
    handleSubmit,
  } = useFormikContext();

  const { getLocalStorage } = useLocalStorage();

  const [
    getCondominiumCameraLive,
    { data: liveData, loading: loadingStream, refetch: refetchLive },
  ] = useLazyQuery(getCondominiumCameraLiveV2, {
    fetchPolicy: 'no-cache',
    onCompleted: ({ condominiumCameraLiveV2 }) => {
      if (!condominiumCameraLiveV2?.length) return;

      const placeIdLocalStorage = getLocalStorage(
        event?.condominium?.personContextId,
      );
      const placeId = event?.placeId || placeIdLocalStorage;

      const placeCamera = placeId
        ? condominiumCameraLiveV2.find(option => option.placeId === +placeId)
        : null;

      setCameraPlaceSelected(placeCamera || condominiumCameraLiveV2[0]);
    },
  });

  useEffect(() => {
    if (windowsWidth <= 1600) {
      setVideoAspectRatio({
        width: MEDIA_ASPECT_RATIO_CONFIG.HD.VIDEO_STREAM.width,
        height: MEDIA_ASPECT_RATIO_CONFIG.HD.VIDEO_STREAM.height,
      });
      setCapturedPhotoAspectRatio({
        width: MEDIA_ASPECT_RATIO_CONFIG.HD.CAPTURED_PHOTO.width,
        height: MEDIA_ASPECT_RATIO_CONFIG.HD.CAPTURED_PHOTO.height,
      });
    }
  }, [windowsWidth]);

  const onError = err => {
    const formattedErrors = apolloErrorHandler(err);
    if (formattedErrors && formattedErrors.length) {
      toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
    }
  };

  const schema = yup.object({
    stepPhotoRegister: yup.object({
      photoRegistered: yup.boolean().when('cameraUnavailable', {
        is: cameraUnavailable => !cameraUnavailable,
        then: yup
          .boolean()
          .oneOf([true], t('step-photo-register.feedback-error')),
      }),
      cameraUnavailable: yup.boolean(),
    }),
  });

  const handleLoadImg = () => {
    if (loadingLive) setLoadingLive(false);
  };

  const handleErrorImg = () => {
    if (loadingLive) setLoadingLive(false);
    setErrorLoadingImg(true);

    if (!values?.stepPhotoRegister?.photoRegistered) {
      setFieldValue('stepPhotoRegister', {
        photoRegistered: false,
        cameraUnavailable: true,
      });
    }
  };

  const handleSendPhoto = async () => {
    setFieldError('stepPhotoRegister.photoRegistered', null);
    const { stepRegisterServiceProvider, stepFindRegister } = values;

    const transientPersonId =
      stepRegisterServiceProvider?.transientPersonId ||
      stepFindRegister?.transientPersonId;

    const data = {
      screenshot: photo,
      transientPersonId,
    };

    setLoadingSubmitPhoto(true);

    try {
      await uploadFace({ data, event, sync: enableSyncFaceOnUploadImage });
      setFieldValue('stepPhotoRegister', {
        photoRegistered: true,
      });
      toast.fire({
        title: t('step-photo-register.success-submit-photo'),
        icon: 'success',
      });
      setPhotoSent(true);
      setTimeout(() => setPhotoSent(false), 2000);
      setLoadingSubmitPhoto(false);
      handleSubmit();
    } catch (err) {
      setLoadingSubmitPhoto(false);
      onError(err);
    }
  };

  const handleTryAgain = () => {
    setFieldError('stepPhotoRegister.photoRegistered', null);
    setPhoto(null);
    setLoadingLive(true);
    refetchLive();
  };

  const captureScreenshot = () => {
    setFieldError('stepPhotoRegister.photoRegistered', null);
    setCapturingPhoto(true);
    html2canvas(videoRef.current, {
      useCORS: true,
    })
      .then(canvas => {
        const cropCanvas = document.createElement('canvas');

        cropCanvas.width = capturedPhotoAspectRatio.width;
        cropCanvas.height = capturedPhotoAspectRatio.height;

        const cropCtx = cropCanvas.getContext('2d');
        const clipStartXCoordinate =
          (capturedPhotoAspectRatio.width - videoAspectRatio.width) / 2;

        cropCtx.drawImage(
          canvas,
          clipStartXCoordinate,
          0,
          videoAspectRatio.width,
          videoAspectRatio.height,
        );

        const croppedDataURL = cropCanvas.toDataURL();

        setPhoto(croppedDataURL);
        setCapturingPhoto(false);
      })
      .finally(() => setCapturingPhoto(false));
  };

  const handleFallback = () => {
    history.push(`/guided-attendance/${eventId}/fallback`);
  };

  const renderPlayerButtonContent = () => {
    if (!photo) {
      if (!loadingLive && errorLoadingImg) {
        return (
          <PlayerButtonContent text={t('step-photo-register.skip-step')} />
        );
      }

      if (capturingPhoto) {
        return (
          <PlayerButtonContent
            text={t('step-photo-register.capturing-photo')}
            icon="sending"
            iconRight
          />
        );
      }

      if (!capturingPhoto) {
        return (
          <PlayerButtonContent
            text={t('step-photo-register.capture-photo')}
            icon="camera"
          />
        );
      }
    }

    if (loadingSubmitPhoto) {
      return (
        <PlayerButtonContent
          text={t('step-photo-register.sending')}
          icon="sending"
          iconRight
        />
      );
    }

    if (!values?.stepPhotoRegister?.photoRegistered) {
      return (
        <PlayerButtonContent
          text={t('step-photo-register.send-photo')}
          icon="sendPhoto"
        />
      );
    }

    if (photoSent) {
      return (
        <PlayerButtonContent text={t('step-photo-register.sent')} icon="sent" />
      );
    }

    return (
      <PlayerButtonContent
        text={t('step-photo-register.resend')}
        icon="resend"
      />
    );
  };

  const url = useMemo(() => cameraPlaceSelected?.url, [cameraPlaceSelected]);
  const options = useMemo(() => liveData?.condominiumCameraLiveV2, [liveData]);

  const submitCurrentStepHistory = steps => {
    const { stepPhotoRegister } = steps;
    if (stepPhotoRegister?.photoRegistered) {
      const data = {
        photoRegistered: true,
      };

      handleInsertStepHistory(data, eventId);
    }
  };

  const nextAction = async stepData => {
    submitCurrentStepHistory(stepData);

    const nextStep = await handleNextAction({
      values: stepData,
      fieldValue: 'stepPhotoRegister',
    });
    return { nextStep, currentStepData: stepData };
  };

  useEffect(() => {
    if (event?.condominium?.personContextId) {
      getCondominiumCameraLive({
        variables: {
          condominiumPersonContextId: event?.condominium?.personContextId,
        },
      });
    }
  }, [event?.condominium?.personContextId]);

  useEffect(() => {
    if (!values?.stepPhotoRegister?.photoRegistered) {
      setFieldValue('stepPhotoRegister', {
        photoRegistered: false,
        cameraUnavailable: false,
      });
    }

    setContextTree({
      formikSchema: schema,
      nextAction,
    });
  }, []);

  useLayoutEffect(() => {
    return () => {
      if (imgRef?.current) {
        imgRef.current.src = '';
      }
    };
  }, []);

  return (
    <StepStructure
      type={StepTextTypeColor.TAKE_PHOTO}
      text={
        photo
          ? t('step-photo-register.preview-photo-title')
          : t('step-photo-register.title', {
              name: values?.stepAttendanceGetName?.name,
            })
      }
      hasPreviousStepButton
    >
      {!photo && (
        <Flex flexDirection="column" width="100%">
          <Text>
            <Trans>
              {t('step-photo-register.photo-of', {
                triage: t(
                  `step-photo-register.triage.${handleGetTriageSelected(
                    values,
                  )}`,
                ),
              })}
            </Trans>
          </Text>
          <Flex gridGap="4px" alignItems="center">
            <MdIcons mdIconName="error" size="20px" color="orange500" />
            <Text fontSize="12px" color="orange500">
              <Trans>{t('step-photo-register.info')}</Trans>
            </Text>
          </Flex>
        </Flex>
      )}

      <S.Wrapper>
        <S.WrapperPlayer width={videoAspectRatio.width}>
          {loadingLive && <S.Spinner size={24} color="white" />}
          {!loadingStream && url ? (
            <>
              {photo ? (
                <img
                  width={capturedPhotoAspectRatio.width}
                  height={capturedPhotoAspectRatio.height}
                  src={photo}
                  alt="captured-face"
                  crossOrigin="anonymous"
                />
              ) : (
                <>
                  {!errorLoadingImg ? (
                    <S.MJPEGPlayerMask loading={+loadingLive}>
                      <MJPEGPlayer
                        ref={videoRef}
                        imgRef={imgRef}
                        onLoad={handleLoadImg}
                        onError={handleErrorImg}
                        url={url}
                        width={videoAspectRatio.width}
                        height={videoAspectRatio.height}
                      />
                    </S.MJPEGPlayerMask>
                  ) : (
                    <Flex
                      justifyContent="center"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <MdVideocamOff size={40} color="white" />
                      <Text fontSize="14px" color="white">
                        {t('step-photo-register.camera-unavailable-feedback')}
                      </Text>
                    </Flex>
                  )}
                </>
              )}
            </>
          ) : (
            <>{!loadingLive && <S.Spinner size={24} color="white" />}</>
          )}
        </S.WrapperPlayer>
        <S.PlayerActionArea>
          {!event?.placeId && !photo && (
            <SelectRegistrationCameraLocation
              options={options}
              condominiumPersonContextId={event?.condominium?.personContextId}
              cameraPlaceSelected={cameraPlaceSelected}
              setCameraPlaceSelected={setCameraPlaceSelected}
              loadingLive={loadingLive}
              setLoadingLive={setLoadingLive}
              errorLoadingImg={errorLoadingImg}
              setErrorLoadingImg={setErrorLoadingImg}
              refetchLive={refetchLive}
            />
          )}
          <Flex
            gridGap="8px"
            flexGrow="1"
            alignItems={event?.placeId ? 'center' : 'initial'}
            justifyContent={event?.placeId ? 'center' : 'flex-end'}
          >
            {photo ? (
              <>
                <S.PlayerButton
                  type="button"
                  disabled={false}
                  onClick={handleTryAgain}
                >
                  {t('step-photo-register.try-again')}
                </S.PlayerButton>
                <S.PlayerButton
                  type="button"
                  disabled={loadingSubmitPhoto || photoSent}
                  onClick={handleSendPhoto}
                  success={photoSent}
                >
                  {renderPlayerButtonContent()}
                </S.PlayerButton>
              </>
            ) : (
              <S.PlayerButton
                type="button"
                disabled={capturingPhoto || loadingLive}
                onClick={() =>
                  !errorLoadingImg ? captureScreenshot() : handleFallback()
                }
              >
                {renderPlayerButtonContent()}
              </S.PlayerButton>
            )}
          </Flex>
        </S.PlayerActionArea>
        {!!errors?.stepPhotoRegister?.photoRegistered &&
          !!touched?.stepPhotoRegister?.photoRegistered && (
            <ErrorMessage>
              <Trans>{errors.stepPhotoRegister.photoRegistered}</Trans>
            </ErrorMessage>
          )}
      </S.Wrapper>
    </StepStructure>
  );
};

export default StepPhotoRegister;

StepPhotoRegister.propTypes = {
  event: propTypes.object.isRequired,
  history: propTypes.object,
  eventId: propTypes.string.isRequired,
};

StepPhotoRegister.defaultProps = {
  history: {},
};
