import { useMutation } from 'react-apollo';

import { finishEvent } from '@kiper/monitoring-graphql/guided_attendance';
import { useContext } from 'react';
import { EventAttendanceContext, TriageContext } from '../../../../store';

const useFinishEvent = ({ handleClearLocalStorage }) => {
  const { handleCheckToClearEventFilter } = useContext(EventAttendanceContext);
  const { handleClear: handleClearTriage } = useContext(TriageContext);

  const [finish, { loading: loadingFinish }] = useMutation(finishEvent, {
    onCompleted: () => {
      handleClearLocalStorage();
      handleClearTriage();
      handleCheckToClearEventFilter();
    },
  });
  return { finish, loadingFinish };
};

export default useFinishEvent;
