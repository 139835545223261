import { useFormik } from 'formik';
import useGetUnitySchema from './useGetUnitySchema';
import useCreateUnity from './useCreateUnity';
import useUpdateUnity from './useUpdateUnity';

const useUnityForm = ({
  unity,
  unities,
  commercialPersonContextId,
  unityGroupId,
  handleToggle,
  t,
}) => {
  const handleValidName = value => {
    const matchingUnity = unities.find(
      unit => unit.name.trim().toLowerCase() === value.trim().toLowerCase(),
    );
    if (matchingUnity && +matchingUnity.id !== +unity?.id) {
      return false;
    }
    return true;
  };

  const schema = useGetUnitySchema(t, handleValidName);

  const { create } = useCreateUnity(t, handleToggle);
  const { update } = useUpdateUnity(t, handleToggle);

  const onSubmit = values => {
    const serializedParams = { ...values.serializedParams };

    if (values?.defaultAccessProfile?.value)
      serializedParams.defaultAccessProfile = values.defaultAccessProfile.value;

    const unityBody = {
      name: values.name,
      commercialUnityPersonContextId:
        +values?.condominiumCommercialUnity?.value || null,
      commercialPersonContextId,
      placeTypeLabelId: +values?.placeTypeLabel?.value || null,
      serializedParams: serializedParams
        ? JSON.stringify(serializedParams)
        : null,
    };

    if (!values?.id) {
      return create({
        variables: { unity: { ...unityBody, unityGroupId } },
      });
    }
    return update({
      variables: { unity: unityBody, unityId: values?.id },
    });
  };

  const getInitialValue = () => {
    const initialValues = unity || {
      name: '',
      condominiumCommercialUnity: '',
      placeTypeLabel: '',
      defaultAccessProfile: null,
      commercialPersonContextId: null,
      serializedParams: null,
    };

    if (unity?.id) {
      if (unity?.placeTypeLabelId)
        initialValues.placeTypeLabel = {
          value: unity.placeTypeLabelId,
        };

      if (unity?.commercialUnityPersonContextId)
        initialValues.condominiumCommercialUnity = {
          value: unity.commercialUnityPersonContextId,
        };

      if (unity?.serializedParams?.defaultAccessProfile)
        initialValues.defaultAccessProfile =
          unity.serializedParams.defaultAccessProfile;
    }
    return initialValues;
  };

  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    dirty,
    isSubmitting,
  } = useFormik({
    initialValues: getInitialValue(),
    validationSchema: schema,
    onSubmit,
    validateOnChange: false,
  });

  return {
    values,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    dirty,
    isSubmitting,
  };
};

export default useUnityForm;
