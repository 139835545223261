import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { useSwal } from '@kiper/hooks';
import { useTranslation } from 'react-i18next';
import { mutations } from '@kiper/monitoring-graphql/pre_register';
import { useFormatApolloErrorHandler } from '../../../../hooks';

const useFetchDenyOrApproveUser = ({
  unityUsers,
  refetchUsersWithPendingApproval,
  refetchUnities,
  condominium,
  unityIdByUser,
}) => {
  const { toast } = useSwal();
  const { t } = useTranslation('user');
  const { onError } = useFormatApolloErrorHandler();

  const handleUpdateUsersList = useCallback(() => {
    const isLastPersonFromUnity = unityUsers[unityIdByUser].length === 1;

    if (isLastPersonFromUnity) {
      return refetchUnities({
        variables: {
          condominiumContextId: condominium?.value,
        },
      });
    }

    refetchUnities({
      variables: {
        condominiumContextId: condominium?.value,
      },
    });

    return refetchUsersWithPendingApproval({
      variables: {
        unityId: +unityIdByUser,
      },
    });
  }, [unityUsers, unityUsers[unityIdByUser], unityIdByUser]);

  const [approveUser, { loading: loadingApproveUser }] = useMutation(
    mutations.approvePreRegister,
    {
      onCompleted: () => {
        handleUpdateUsersList();
        toast.fire({
          title: t('pending-approval.confirm-approve-toast-title'),
          icon: 'success',
        });
      },
      onError,
    },
  );

  const [denyUser, { loading: loadingDenyUser }] = useMutation(
    mutations.denyPreRegister,
    {
      onCompleted: () => {
        handleUpdateUsersList();
        toast.fire({
          title: t('pending-approval.confirm-deny-toast-title'),
          icon: 'success',
        });
      },
      onError,
    },
  );

  return { approveUser, denyUser, loadingApproveUser, loadingDenyUser };
};

export default useFetchDenyOrApproveUser;
