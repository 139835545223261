import styled from 'styled-components';
import { CheckBox as checkbox, Input } from '@kiper/ui';

export const CheckBox = styled(checkbox)`
  width: 16px;
  height: 16px;
`;

export const InputNumber = styled(Input)`
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  -moz-appearance: textfield;

  &:disabled {
    color: ${props => props.theme.colors.secondary500};
  }
`;
