import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Flex, MdIcons, Text } from '@kiper/ui';
import { useToggle } from '@kiper/hooks';
import DoorStatus from '../DoorStatus';
import { useDoorCommands, useDoorStatus } from '../../hooks';
import { DOOR_COMMANDS } from '../../../../../constants';
import KeepOpenDoorReasonModal from '../../../../../pages/EventAttendance/Detail/Cameras/Modals/ReasonModal';
import { useVoice } from '../../../../../hooks';
import { CallButton, CommandButton } from './styles';

const DeviceCommand = ({
  device,
  event,
  selectedPlace,
  place,
  key,
  deviceStatus,
}) => {
  const { t } = useTranslation('guided-attendance');
  const [dropdownOpen, toggleDropDown] = useToggle(false);

  const {
    handleGetCommandsButton,
    hasOpenDoorCommand,
    handleDoorCommand,
    otherCommands,
    loading,
    openReasonKeepOpenDoorModal,
    toggleReasonKeepOpenDoorModal,
    handleSendKeepOpenReason,
  } = useDoorCommands({ device, event });

  const { doorStatus } = useDoorStatus({ device, deviceStatus });

  const { clickToCall, loading: loadingCall } = useVoice();

  const handleClickToCall = () => {
    clickToCall({
      voiceCallInput: {
        destinationKind: device?.audioCall?.destinationKind,
        destination: device?.audioCall?.destination,
      },
      feedbackText: t('step-open-doors.commands.feedbacks.calling'),
      eventId: event?.eventId,
      partner: event?.partner?.name,
      condominium: event?.condominium?.name,
      eventCode: event?.eventType,
    });
  };

  return (
    <Flex
      key={key}
      gridGap="8px"
      alignItems="center"
      justifyContent="space-between"
    >
      <Flex gridGap="4px" flexDirection="column">
        <Text>{device?.name}</Text>
        <DoorStatus
          doorStatus={doorStatus || device?.status}
          doorKeepOpenedReason={null}
        />
      </Flex>

      <Flex gridGap="4px">
        {!!device?.audioCall && (
          <CallButton
            variant="out"
            color="primary"
            bg="white"
            size="sm"
            icon={<MdIcons mdIconName="call" size="20px" />}
            marginright={!hasOpenDoorCommand ? '36px' : 0}
            onClick={handleClickToCall}
            disabled={selectedPlace?.id !== place.id || loadingCall}
          >
            {t('step-open-doors.commands.call')}
          </CallButton>
        )}
        {hasOpenDoorCommand && (
          <CommandButton
            size="sm"
            color="primary"
            icon={<MdIcons mdIconName="door_open" size="20px" />}
            onClick={() =>
              handleDoorCommand(DOOR_COMMANDS.OPEN, device?.id, device?.doorId)
            }
            disabled={selectedPlace?.id !== place.id || loading}
          >
            {t('step-open-doors.commands.open')}
          </CommandButton>
        )}
        {!!otherCommands?.length && (
          <Dropdown
            direction="down"
            isOpen={dropdownOpen}
            toggle={toggleDropDown}
          >
            <DropdownToggle tag="nav" aria-expanded={dropdownOpen}>
              <CommandButton
                variant="outlined"
                color="neutral"
                bg="white"
                size="sm"
                disabled={selectedPlace?.id !== place.id || loading}
                icon={<MdIcons mdIconName="keyboard_arrow_down" size="20px" />}
              />
            </DropdownToggle>
            <DropdownMenu right>
              {handleGetCommandsButton(otherCommands)?.map(command => (
                <CommandButton
                  block
                  key={command?.label}
                  disabled={loading}
                  variant="text"
                  color="neutral"
                  icon={command.icon}
                  onClick={() => {
                    command.action();
                    toggleDropDown();
                  }}
                >
                  {command?.label}
                </CommandButton>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
      </Flex>
      {openReasonKeepOpenDoorModal && (
        <KeepOpenDoorReasonModal
          onToggle={toggleReasonKeepOpenDoorModal}
          open={openReasonKeepOpenDoorModal}
          sendReason={handleSendKeepOpenReason}
        />
      )}
    </Flex>
  );
};

export default DeviceCommand;

DeviceCommand.propTypes = {
  device: propTypes.object.isRequired,
  selectedPlace: propTypes.object.isRequired,
  place: propTypes.object.isRequired,
  key: propTypes.number.isRequired,
  event: propTypes.object.isRequired,
  deviceStatus: propTypes.array.isRequired,
};
