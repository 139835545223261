import { Form, FormFeedback } from 'reactstrap';
import propTypes from 'prop-types';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Modal, Flex, Label, Text, Button, Input } from '@kiper/ui';
import { useInsertStepHistory } from '../../../../hooks';
import { useLocalStorage } from '../hooks';

const ObservationModal = ({ eventId, open, toggleModal }) => {
  const [t] = useTranslation('guided-attendance');
  const { handleInsertStepHistory } = useInsertStepHistory();
  const { saveLocalStorage, localStorageData } = useLocalStorage(
    'event-treatment',
    eventId,
  );

  const validationSchema = Yup.object({
    treatment: Yup.string().required(
      t('step-open-doors.modals.observation.input-error-message'),
    ),
  });

  const onSubmit = data => {
    handleInsertStepHistory(data, eventId);
    saveLocalStorage(data.treatment);
    toggleModal();
  };

  const { setFieldValue, values, touched, errors, handleSubmit } = useFormik({
    initialValues: { treatment: localStorageData || '' },
    validationSchema,
    onSubmit,
  });

  return (
    <Modal
      title={
        <Text fontSize={16} fontWeight={400} lineHeight="20px">
          {t('step-open-doors.modals.observation.header-title')}
        </Text>
      }
      open={open}
      toggle={toggleModal}
    >
      <Form onSubmit={handleSubmit}>
        <Flex flexDirection="column" gridGap="8px">
          <Flex flexDirection="column">
            <Label>{t('step-open-doors.observation')}</Label>
            <Input
              type="textarea"
              rows={3}
              id="treatment"
              name="treatment"
              placeholder={t('step-open-doors.modals.observation.placeholder')}
              onChange={(name, value) => setFieldValue(name, value)}
              value={values?.treatment}
              invalid={!!errors?.treatment && touched?.treatment}
            />
            <FormFeedback>{errors?.treatment}</FormFeedback>
          </Flex>
          <Flex justifyContent="flex-end">
            <Button
              color="secondary"
              outline
              className="mr-3"
              type="button"
              onClick={toggleModal}
            >
              {t('step-open-doors.modals.observation.cancel-button')}
            </Button>
            <Button type="submit" color="primary">
              {t('step-open-doors.modals.observation.register-button')}
            </Button>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  );
};

export default ObservationModal;

ObservationModal.propTypes = {
  eventId: propTypes.string.isRequired,
  open: propTypes.bool.isRequired,
  toggleModal: propTypes.func.isRequired,
};
