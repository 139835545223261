const useLocalStorage = (key, eventId) => {
  const localStorageKey = `${key}_${eventId}`;

  const saveLocalStorage = value => {
    if (!key || !eventId) return null;
    return localStorage.setItem(localStorageKey, value);
  };

  const localStorageData = localStorage.getItem(localStorageKey);

  const handleClearLocalStorage = () => {
    localStorage.clear(localStorageKey);
  };

  return { saveLocalStorage, localStorageData, handleClearLocalStorage };
};

export default useLocalStorage;
