import { useTranslation } from 'react-i18next';
import { MdVideocamOff } from 'react-icons/md';
import { Text } from '@kiper/ui';
import { Wrapper } from './styles';

const CameraOfflineWrapper = () => {
  const { t } = useTranslation('guided-attendance');
  return (
    <Wrapper>
      <MdVideocamOff size={40} color="white" />
      <Text fontSize="14px" color="white">
        {t('step-open-doors.no-camera-available')}
      </Text>
    </Wrapper>
  );
};
export default CameraOfflineWrapper;
