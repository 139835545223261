import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@kiper/ui';
import PlaceCamera from '../PlaceCamera';
import useMaxDimensions from '../../../../../hooks/useMaxDimensions';
import { Container } from './styles';
import CondominiumMap from '../CondominiumMap';

const heightToBeDiscounted = 48;
const PlaceInformation = ({
  event,
  places,
  selectedPlace,
  handleSelectedPlace,
  deviceStatus,
}) => {
  const { maxHeight, elementRef } = useMaxDimensions({ heightToBeDiscounted });
  const { t } = useTranslation('guided-attendance');

  return (
    <Flex
      maxWidth="518px"
      width="100%"
      flexDirection="column"
      pl="8px"
      gridGap="8px"
      mt="-8px"
    >
      <Text fontWeight="bolder">
        {t(`step-open-doors.location-information`, {
          name: selectedPlace?.name,
        })}
      </Text>
      <Container ref={elementRef} maxheight={maxHeight}>
        <PlaceCamera event={event} selectedPlace={selectedPlace} />
        <CondominiumMap
          event={event}
          places={places}
          selectedPlace={selectedPlace}
          handleSelectedPlace={handleSelectedPlace}
          deviceStatus={deviceStatus}
        />
      </Container>
    </Flex>
  );
};
export default PlaceInformation;

PlaceInformation.propTypes = {
  event: propTypes.object.isRequired,
  places: propTypes.array.isRequired,
  selectedPlace: propTypes.object.isRequired,
  handleSelectedPlace: propTypes.func.isRequired,
  deviceStatus: propTypes.array.isRequired,
};
