import propTypes from 'prop-types';
import { Badge } from '@kiper/ui';
import { erpLicenses } from '../../constants';

const LicenseBadges = ({ licenseName, t }) => {
  switch (licenseName) {
    case erpLicenses.remote_concierge:
      return (
        <Badge color="primary">
          {t(`list.erp_licenses.${erpLicenses.remote_concierge}`)}
        </Badge>
      );
    case erpLicenses.access_control:
      return (
        <Badge color="info500">
          {t(`list.erp_licenses.${erpLicenses.access_control}`)}
        </Badge>
      );
    case erpLicenses.porter_delivery:
      return (
        <Badge color="danger">
          {t(`list.erp_licenses.${erpLicenses.porter_delivery}`)}
        </Badge>
      );
    case erpLicenses.video_call:
      return (
        <Badge color="purple900">
          {t(`list.erp_licenses.${erpLicenses.video_call}`)}
        </Badge>
      );
    case erpLicenses.commercial:
      return (
        <Badge color="orange500">
          {t(`list.erp_licenses.${erpLicenses.commercial}`)}
        </Badge>
      );
    case erpLicenses.commercial_unity:
      return (
        <Badge color="warning200">
          {t(`list.erp_licenses.${erpLicenses.commercial_unity}`)}
        </Badge>
      );
    case erpLicenses.nonexistent:
      return <Badge>{t(`list.erp_licenses.${erpLicenses.nonexistent}`)}</Badge>;
    default:
      return <Badge>{licenseName}</Badge>;
  }
};

export default LicenseBadges;

LicenseBadges.propTypes = {
  licenseName: propTypes.string.isRequired,
  t: propTypes.func.isRequired,
};
