import { apolloErrorHandler } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';
import { useLazyQuery } from 'react-apollo';
import { event as eventsGql } from '@kiper/monitoring-graphql';
import { useEffect, useState } from 'react';

const findPrincipalCamera = cameras => {
  return cameras.find(camera => {
    const isPrincipal = JSON.parse(camera.serializedParams)?.isPrincipal;
    return isPrincipal;
  });
};

const findOnlineCamera = cameras => {
  return cameras.find(camera => camera.isOnline);
};

const useGetEventCameras = ({ eventId, selectedPlace }) => {
  const [allCameras, setAllCameras] = useState([]);
  const [placeCameras, setPlaceCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const { toast } = useSwal();

  const selectCameras = cameras => {
    const isPrincipalCamera = findPrincipalCamera(cameras);
    if (isPrincipalCamera) {
      setSelectedCamera(isPrincipalCamera);
      setPlaceCameras(cameras.filter(camera => camera !== isPrincipalCamera));
      return;
    }

    const firstCameraOnline = findOnlineCamera(cameras);
    if (firstCameraOnline) {
      setSelectedCamera(firstCameraOnline);
      setPlaceCameras(cameras.filter(camera => camera !== firstCameraOnline));
      return;
    }

    setSelectedCamera(cameras[0]);
    setPlaceCameras([]);
  };

  const [getAllCameras, { loading: loadingCameras }] = useLazyQuery(
    eventsGql.getSingleCameras,
    {
      fetchPolicy: 'no-cache',
      onCompleted: ({ eventSingleCameras }) => {
        if (eventSingleCameras?.length) {
          setAllCameras(eventSingleCameras);
        }
      },
      onError: err => {
        const formattedErrors = apolloErrorHandler(err);
        if (formattedErrors && formattedErrors.length)
          toast.fire({ title: formattedErrors.join('\n'), icon: 'error' });
      },
    },
  );

  const handleSelectCamera = camera => {
    setSelectedCamera(camera);
    setPlaceCameras(prevCameras =>
      prevCameras.filter(c => c !== camera).concat(selectedCamera),
    );
  };

  useEffect(() => {
    if (allCameras?.length) {
      const camerasOfPlace = allCameras.filter(
        camera => camera.placeId === selectedPlace?.id,
      );
      if (camerasOfPlace?.length) {
        selectCameras(camerasOfPlace);
      } else {
        setSelectedCamera(null);
        setPlaceCameras([]);
      }
    }
  }, [selectedPlace, allCameras]);

  useEffect(() => {
    if (eventId) {
      getAllCameras({ variables: { eventId } });
    }
  }, [eventId]);

  return {
    placeCameras,
    selectedCamera,
    handleSelectCamera,
    loadingCameras,
  };
};

export default useGetEventCameras;
