import { Flex } from '@kiper/ui';
import { useTranslation } from 'react-i18next';
import emptyStateImage from '../../../../../assets/images/empty-state-map.svg';
import * as S from './styles';

const CondoMapEmptyState = () => {
  const { t } = useTranslation('guided-attendance');

  return (
    <S.EmptyStateContainer>
      <S.Img src={emptyStateImage} alt="empty-state-map" />
      <Flex flexDirection="column" gridGap="12px">
        <S.Title>{t('step-open-doors.map.empty-state.title')}</S.Title>
        <S.Text>{t('step-open-doors.map.empty-state.text')}</S.Text>
      </Flex>
    </S.EmptyStateContainer>
  );
};

export default CondoMapEmptyState;
