import { apolloDataErrorHandlerV2 } from '@kiper/fns';
import { useSwal } from '@kiper/hooks';

const useFormatApolloErrorHandler = () => {
  const { toast, linkToast } = useSwal();

  const toastFireError = title => toast.fire({ title, icon: 'error' });

  const onError = err => {
    const formattedErrors = apolloDataErrorHandlerV2(err);
    const errorWithSpecificities = formattedErrors?.specificities;
    const errorWithMessage = formattedErrors?.message;
    const errorIsArray =
      Array.isArray(formattedErrors) && !!formattedErrors.length;
    const errorIsRawString =
      formattedErrors && typeof formattedErrors === 'string';

    if (errorIsRawString) return toastFireError(formattedErrors);

    if (errorIsArray) return toastFireError(formattedErrors.join('\n'));

    if (errorWithSpecificities)
      return linkToast({
        title: formattedErrors.message,
        icon: 'error',
        linkLabel: formattedErrors.specificities.personName,
        href: `/users/${formattedErrors.specificities.personId}`,
        timer: 5000,
      });

    if (errorWithMessage) return toastFireError(formattedErrors.message);

    return null;
  };

  return { onError };
};

export default useFormatApolloErrorHandler;
