import * as yup from 'yup';

const useGetUnitySchema = (t, handleValidName) => {
  const schema = yup.object({
    name: yup
      .string()
      .trim()
      .required(t('common:feedback.required-field'))
      .test(
        'unique-name',
        t('details.modal.unity.unity-name-already-in-use'),
        value => value && handleValidName(value),
      ),
    condominiumCommercialUnity: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable(),
    commercialPersonContextId: yup.number().nullable(),
    placeTypeLabel: yup
      .object({
        label: yup.string(),
        value: yup.number(),
      })
      .nullable(),
    serializedParams: yup
      .object({
        extension: yup
          .string()
          .matches(/^\d+$/, t('common:feedback.only-numbers'))
          .min(1, t('common:feedback.min', { number: 1 }))
          .max(5, t('common:feedback.max', { number: 5 })),
        defaultAccessProfile: yup.number().nullable(),
      })
      .nullable(),
  });

  return schema;
};

export default useGetUnitySchema;
