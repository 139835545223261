import { lazy, Suspense } from 'react';
import propTypes from 'prop-types';
import { Loader } from '@kiper/ui';
import { tabIds } from '../hooks';
import * as S from './styles';

const CondominiumSipConfiguration = lazy(() =>
  import('../../CondominiumSipConfiguration'),
);
const CondominiumApplicationParameters = lazy(() =>
  import('../../CondominiumApplicationParameters'),
);
const CondominiumAttendanceSettings = lazy(() =>
  import('../../CondominiumAttendanceSettings'),
);
const CondominiumGeneralData = lazy(() =>
  import('../../CondominiumGeneralData'),
);
const CondominiumUnityConfiguration = lazy(() =>
  import('../../CondominiumUnityConfiguration'),
);
const CondominiumActivityManagement = lazy(() =>
  import('../../CondominiumActivityManagement'),
);
const CondominiumRulesManagement = lazy(() => import('../../CondominiumRules'));
const CondominiumRulesWithSubtriage = lazy(() =>
  import('../../CondominiumRulesWithSubtriage'),
);
const CondominiumExportations = lazy(() =>
  import('../../CondominiumExportations'),
);

const TabContent = ({ activeTab, children }) => (
  <S.TabContent activeTab={activeTab}>{children}</S.TabContent>
);

export const GeneralData = () => (
  <S.TabPane tabId={tabIds.GENERAL_DATA}>
    <Suspense fallback={<Loader fullHeight />}>
      <CondominiumGeneralData />
    </Suspense>
  </S.TabPane>
);

export const AttendanceSettings = ({
  condominium,
  activeTab,
  showAttendanceSettings,
}) =>
  showAttendanceSettings &&
  activeTab === tabIds.ATTENDANCE_SETTINGS && (
    <S.TabPane tabId={tabIds.ATTENDANCE_SETTINGS}>
      <Suspense fallback={<Loader fullHeight />}>
        <CondominiumAttendanceSettings condominium={condominium} />
      </Suspense>
    </S.TabPane>
  );

export const ApplicationParameters = ({
  condominium,
  activeTab,
  showApplicationParameters,
}) =>
  showApplicationParameters &&
  activeTab === tabIds.APPLICATION_PARAMETERS && (
    <S.TabPane tabId={tabIds.APPLICATION_PARAMETERS}>
      <Suspense fallback={<Loader fullHeight />}>
        <CondominiumApplicationParameters condominium={condominium} />
      </Suspense>
    </S.TabPane>
  );

export const UnityConfiguration = ({
  condominium,
  activeTab,
  handleChangeTab,
}) =>
  activeTab === tabIds.UNITY_CONFIGURATION && (
    <S.TabPane tabId={tabIds.UNITY_CONFIGURATION}>
      <Suspense fallback={<Loader fullHeight />}>
        <CondominiumUnityConfiguration
          condominium={condominium}
          handleChangeTab={handleChangeTab}
        />
      </Suspense>
    </S.TabPane>
  );

export const ActivityManagement = ({ condominium, activeTab }) =>
  activeTab === tabIds.ACTIVITY_MANAGEMENT && (
    <S.TabPane tabId={tabIds.ACTIVITY_MANAGEMENT}>
      <Suspense fallback={<Loader fullHeight />}>
        <CondominiumActivityManagement condominium={condominium} />
      </Suspense>
    </S.TabPane>
  );

export const SipConfiguration = ({
  condominium,
  activeTab,
  showSipSettings,
  updateCondominium,
}) =>
  showSipSettings &&
  activeTab === tabIds.SIP_CONFIGURATION && (
    <S.TabPane tabId={tabIds.SIP_CONFIGURATION}>
      <Suspense fallback={<Loader fullHeight />}>
        <CondominiumSipConfiguration
          condominium={condominium}
          updateCondominium={updateCondominium}
        />
      </Suspense>
    </S.TabPane>
  );

export const RulesManagement = ({ activeTab, condominium }) => {
  const condominiumWithSubtriage = condominium?.params?.some(
    param => param?.name === 'hasSubtriage' && param?.value === 'true',
  );

  return (
    activeTab === tabIds.CONDOMINIUM_RULES && (
      <S.TabPane tabId={tabIds.CONDOMINIUM_RULES}>
        <Suspense fallback={<Loader fullHeight />}>
          {condominiumWithSubtriage ? (
            <CondominiumRulesWithSubtriage />
          ) : (
            <CondominiumRulesManagement />
          )}
        </Suspense>
      </S.TabPane>
    )
  );
};

export const Exportations = ({ condominium, activeTab }) =>
  activeTab === tabIds.EXPORTATIONS && (
    <S.TabPane tabId={tabIds.EXPORTATIONS}>
      <Suspense fallback={<Loader fullHeight />}>
        <CondominiumExportations condominium={condominium} />
      </Suspense>
    </S.TabPane>
  );

export default TabContent;

TabContent.propTypes = {
  activeTab: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
};

AttendanceSettings.propTypes = {
  condominium: propTypes.object.isRequired,
  activeTab: propTypes.string.isRequired,
  showAttendanceSettings: propTypes.bool.isRequired,
};

ApplicationParameters.propTypes = {
  condominium: propTypes.object.isRequired,
  activeTab: propTypes.string.isRequired,
  showApplicationParameters: propTypes.bool.isRequired,
};

UnityConfiguration.propTypes = {
  condominium: propTypes.object.isRequired,
  activeTab: propTypes.string.isRequired,
  handleChangeTab: propTypes.func.isRequired,
};

ActivityManagement.propTypes = {
  condominium: propTypes.object.isRequired,
  activeTab: propTypes.string.isRequired,
};

SipConfiguration.propTypes = {
  condominium: propTypes.object.isRequired,
  activeTab: propTypes.string.isRequired,
  showSipSettings: propTypes.bool.isRequired,
  updateCondominium: propTypes.func.isRequired,
};

RulesManagement.propTypes = {
  activeTab: propTypes.string.isRequired,
  condominium: propTypes.object.isRequired,
};

Exportations.propTypes = {
  condominium: propTypes.object.isRequired,
  activeTab: propTypes.string.isRequired,
};
