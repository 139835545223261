import { useEffect, useState, useMemo } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Flex, Text } from '@kiper/ui';
import { useWindowDimensions } from '@kiper/hooks';
import CondominiumMapPlace from '../CondominiumMapPlace';
import { MapContainer } from './styles';
import CondoMapEmptyState from './CondoMapEmptyState';

const MAP_SIZE_WIDTH = {
  HD: 432,
  FULLHD: 500,
};

const RESOLUTIONS = {
  HD: 1366,
  FULLHD: 1920,
};

const CondominiumMap = ({
  event,
  selectedPlace,
  handleSelectedPlace,
  places,
  deviceStatus,
}) => {
  const [sizeMap, setSizeMap] = useState(MAP_SIZE_WIDTH.HD);
  const { t } = useTranslation('guided-attendance');
  const { width } = useWindowDimensions();

  const urlMap = useMemo(() => {
    const urlMapValue = event?.condominium?.params?.find(
      param => param.name === 'urlMap',
    )?.value;

    if (!urlMapValue) return null;

    return JSON.parse(urlMapValue);
  }, []);

  useEffect(() => {
    if (width > RESOLUTIONS.HD) {
      setSizeMap(MAP_SIZE_WIDTH.FULLHD);
    } else {
      setSizeMap(MAP_SIZE_WIDTH.HD);
    }
  }, [width]);

  return (
    <Flex flexDirection="column" gridGap="4px">
      <Text>{t('step-open-doors.condominium-map')}</Text>
      {urlMap ? (
        <MapContainer size={sizeMap} url={urlMap}>
          {places?.map(place => (
            <CondominiumMapPlace
              key={place?.id}
              place={place}
              selectedPlace={selectedPlace}
              setSelectedPlace={handleSelectedPlace}
              event={event}
              deviceStatus={deviceStatus}
              sizeMap={sizeMap}
            />
          ))}
        </MapContainer>
      ) : (
        <CondoMapEmptyState />
      )}
    </Flex>
  );
};

export default CondominiumMap;

CondominiumMap.propTypes = {
  event: propTypes.object.isRequired,
  places: propTypes.array.isRequired,
  selectedPlace: propTypes.object.isRequired,
  handleSelectedPlace: propTypes.func.isRequired,
  deviceStatus: propTypes.array.isRequired,
};
