import { useSwal } from '@kiper/hooks';
import { mutations } from '@kiper/monitoring-graphql/unity';
import { useMutation } from 'react-apollo';
import { useFormatApolloErrorHandler } from '../../../../hooks';

const useCreateUnity = (t, handleToggle) => {
  const { toast } = useSwal();
  const { onError } = useFormatApolloErrorHandler();

  const [create] = useMutation(mutations.unityCreate, {
    refetchQueries: ['unitiesByUnityGroup'],
    onCompleted: () => {
      toast.fire({
        title: t('details.modal.unity.created'),
        icon: 'success',
      });
      handleToggle();
    },
    onError,
  });

  return { create };
};

export default useCreateUnity;
