import { useMemo } from 'react';
import firstBy from 'thenby';
import { deviceIdTypes } from '../../../../constants';

const MAP_SIZE_BASE = 500;
const usePlaceDeviceInfo = ({ place, eventPlaceId, sizeMap }) => {
  const handleFilterDevices = devices =>
    devices
      ?.filter(
        x =>
          x.deviceTypeId === deviceIdTypes.vehicular ||
          x.deviceTypeId === deviceIdTypes.accessVehicular ||
          x.deviceTypeId === deviceIdTypes.kvoice ||
          x.deviceTypeId === deviceIdTypes.interphone ||
          x.deviceTypeId === deviceIdTypes.access ||
          x.deviceTypeId === deviceIdTypes.indoor ||
          x.deviceTypeId === deviceIdTypes.facial ||
          x.deviceTypeId === deviceIdTypes.facialIntelbras,
      )
      .sort(
        firstBy(
          d =>
            !(
              JSON.parse(d?.serializedParams)?.onlyAudio ||
              (!JSON.parse(d?.serializedParams)?.urlToOpen &&
                d.deviceTypeId === deviceIdTypes.interphone)
            ),
          { direction: 'desc' },
        ),
      );

  const { device, coordinate, isOrigin, deviceOnlyAudio } = useMemo(() => {
    const sortedDevice = handleFilterDevices(place?.devices);
    const firstDevice = sortedDevice?.length ? sortedDevice[0] : null;
    const deviceParams = firstDevice?.serializedParams
      ? JSON.parse(firstDevice?.serializedParams)
      : null;
    const onlyAudio =
      !!firstDevice &&
      deviceParams &&
      (deviceParams?.onlyAudio ||
        (!deviceParams?.urlToOpen &&
          firstDevice?.deviceTypeId === deviceIdTypes.interphone));

    return {
      device: firstDevice,
      coordinate:
        place?.serializedParams &&
        JSON.parse(place?.serializedParams)?.coordinate,
      isOrigin: eventPlaceId === place.id,
      deviceOnlyAudio: onlyAudio,
    };
  }, []);

  const newCoordinates = useMemo(() => {
    if (!coordinate) return null;
    return {
      y: (coordinate.y / MAP_SIZE_BASE) * sizeMap,
      x: (coordinate.x / MAP_SIZE_BASE) * sizeMap,
      ratio: sizeMap / MAP_SIZE_BASE,
    };
  }, [coordinate, sizeMap]);

  return { device, coordinate: newCoordinates, isOrigin, deviceOnlyAudio };
};

export default usePlaceDeviceInfo;
